@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "ARIALBOLD";
    src: url("../fonts/ARIALBD.woff");
  }
@font-face {
    font-family: "ARIAL";
    src: url("../fonts/ARIAL.woff");
  }