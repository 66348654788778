.leaflet-fade-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-zoom-animated { will-change:auto !important; }

.leaflet-container {
  height: 75vh;
}

.customButton {
  text-align: center;
  margin-top: 0px;
  cursor: pointer;
  border-radius: 2px;
  font-size: x-large;
  text-align: center;
  width: 32px;
  padding-left: 3px;
  color: black;
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.leaflet-top .leaflet-control {
  margin-top: 0px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.legend {
  background: #fff;
  padding: 8px;
  border-radius: 5px;
}
.legend .row{
  display: flex;
  line-height: 30px;
}

.legend i {
  border: 1px solid;
  border-color: #ccc;
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}