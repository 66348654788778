#country-map-svg {
    border: 2px solid gray;
    padding: 1px;
    max-width: 1300px;
    width: 90%;
    stroke-linejoin: round;
    stroke: #dfdfdf;
    fill:rgb(193, 193, 193);
}

.country-name-popup {
    background-color: whitesmoke;
    color: black;
}

#BD, #CD, #ET, #GH, #GT, #HN, #KE, #KH, #LR, #MG, #MW, #ML, #MZ, #NP, #NE, #NG, #RW, #SN, #TZ, #UG, #ZM {
    fill: #4799B5;
}
#BD:hover, #CD:hover, #ET:hover, #GH:hover, #GT:hover, #HN:hover, #KE:hover, #KH:hover, #LR:hover, #MG:hover, #MW:hover, #ML:hover, #MZ:hover, #NP:hover, #NE:hover, #NG:hover, #RW:hover, #SN:hover, #TZ:hover, #UG:hover, #ZM:hover {
    cursor: pointer;
    fill: #479ab5ab;
}