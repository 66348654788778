.sdp {
    --theme-color: #8b5cf6 !important; /* violet color */
    --selected-date-color: #eeeeee !important; /* light-gray color */
    --font: 'Kollektif', sans-serif !important;
    font-size: small !important;
    height: auto !important;
    width: auto !important;
  }
  .sdp--dates-grid{
    gap:1px !important;
  }
  .MuiInputBase-input{
    border: 1px solid !important;
  }